// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
exports.push([module.id, "body, html {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    font-family: 'Poppins', sans-serif;\n    scroll-behavior: smooth;\n}\n\n.app {\n    min-height: 100vh\n}\n\na {\n    color: #964d64;\n    text-decoration: none;\n}\n\na:hover {\n    opacity: 80%;\n}\n\nh1 {\n    font-weight: bold;\n}\n\n.home-introduction {\n    text-align: center;\n    min-height: 100vh;\n    margin-bottom: 100px\n}\n\n.header {\n    background-color: #d9cfd2;\n    margin: -8px -8px 0 -8px;\n}\n\n.font-family--monospace {\n    font-family: 'Roboto Mono', monospace;\n}\n\n.social-link {\n    font-size: 24px !important;\n    padding: 0 8px;\n}\n\n.typewriter  {\n    display: inline-block;\n}\n.typewriter p {\n    display: inline-block;\n    overflow: hidden; /* Ensures the content is not revealed until the animation */\n    border-right: .15em solid #964d64; /* The typwriter cursor */\n    white-space: nowrap; /* Keeps the content on a single line */\n    margin: 0 auto 10px; /* Gives that scrolling effect as the typing happens */\n    animation:\n            typing 3.5s steps(47, end),\n            blink-caret .75s step-end infinite;\n}\n\n.pink-font {\n    color: #964d64;\n}\n\n/* The typing effect */\n@keyframes typing {\n    from { width: 0 }\n    to { width: 100% }\n}\n\n/* The typewriter cursor effect */\n@keyframes blink-caret {\n    from, to { border-color: transparent }\n    50% { border-color: #964d64; }\n}\n\n.cards {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}", ""]);
// Exports
module.exports = exports;

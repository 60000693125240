import React from 'react'
import './Header.scss'

export const Header = () => {
    return(<>
        <div className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-links">
                <a className="navbar-item" href="/#about">
                    <span>About</span>
                </a>
                <a className="navbar-item" href="/#projects">
                    <span>Projects</span>
                </a>
            </div>
        </div>
    </>)
}

export default Header;

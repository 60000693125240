import { hot } from 'react-hot-loader/root'
import React from 'react'
import Home from './pages/Home.jsx'


function App() {

  return (
    <Home />
);
}

export default hot(App);

import React from 'react'
import './Project.scss'

export const Project = (props) => {
    const createCard = () => {
        return (<div className='project-card' style={{textAlign: 'left'}}>
        <h3>
            <span className='hover-text-effect'>
                {props.title}
            </span>
        </h3>
        <p >{props.description}</p>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            {props.link && <a href={props.link} target='_blank' aria-label='Project'>
                <i className="fas fa-external-link-alt"/>
            </a>}
        </div>
        </div>)
    }
    if (props.link) {
        return (
            <a href={props.link} target='_blank' aria-label='Project' >
                {createCard()}
            </a>
        )
    } else {
        return createCard()
    }
}

export default Project
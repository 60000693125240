import React, {useEffect, useRef} from 'react';
import SamanthaAnimation from '../components/SamanthaAnimation.jsx'
import samantha from '../static/samantha.png'
import Project from "../components/Project";
import Header from "../components/Header";

const Home = () => {
    const refInput = useRef();
    return (
        <div className="home-introduction">
            <div className='header'>
                <Header/>
                <SamanthaAnimation/>
            </div>

            <div style={{
                display: 'flex',
                backgroundColor: "white",
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap-reverse',
                gap: 30,
                padding: '30px 0'
            }}>
                <div>
                    <img ref={refInput} height="200" width="200" style={{borderRadius: '50%'}} src={samantha}
                         alt={'Samantha smiling with a pink filter'}/>
                    <div>
                        <a href="https://au.linkedin.com/in/samanthachhoeu" target="_blank" className="social-link"
                           title="linkedin">
                            <i className="fa fa-linkedin" aria-label="Linkedin">
                                <span className="sr-only">Linkedin</span>
                            </i>
                        </a>
                        <a href="https://stackoverflow.com/users/8396562/samantha" target="_blank"
                           className="social-link" title="stack overflow">
                            <i className="fab fa-stack-overflow"/>
                        </a>
                        <a href="https://codepen.io/samanthachh" target="_blank" className="social-link"
                           title="codepen">
                            <i className="fab fa-codepen"/>
                        </a>
                    </div>
                </div>
                <div style={{textAlign: "left", maxWidth: '600px', margin: 18}}>
                    <h1 id="about">Samantha Chhoeu</h1>
                    <p>Hi there! Welcome to my digital playground.</p>
                    <p>Being a software engineer is exciting to me because I love solving problems, experimenting and
                        learning.</p>
                    <p>In my free time, I enjoy <a href={'https://www.instagram.com/bitesinbytes/'}
                                                   target={'_blank'}>foodie</a> adventures, taking <a
                        href={'https://www.instagram.com/travelinbytes/'} target={'_blank'}>photos</a>, and reading.</p>
                    <div>

                    </div>
                </div>
            </div>
            <h2 id="projects">Projects</h2>
            <div className='cards'>
                <Project title={'This website'}
                         description={'Simple portfolio site, built with React, CSS(Sass), webpack and CI/CD with Netlify. I played around with CSS animations/effects.'}/>
                <Project title={'Food blog'}
                         description={'A food blog created with React. Posts are fetched using GraphQL from a headless CMS (Gatsby & Sanity). CI/CD with Netlify.'}
                         link='https://bitesinbytes.com/'/>
                <Project title={'Pokemon matching game'}
                         description={'WIP - a pattern matching game I am working on for my cousins who just started getting into Pokemon. Built with vanilla JS.'}
                         link='https://pokemon.samanthachhoeu.com/'/>
            </div>
            <div className='photos'>
            </div>
        </div>
    )
}

export default Home;

import React from 'react';
import './SamanthaAnimation.scss'
class SamanthaAnimation extends React.Component {
  render () {
    return (
      <div className="main">
        <div className="background"  role='img' aria-roledescription='Animation of a girl looking at a computer screen with code scrolling up'>
          <div className="computer">
            <div className="computer__screen">
              <div className="computer__scrolling-code">
                <div className="code code--green"/>
                <div className="code code--yellow"></div>
                <div className="code code--blue"></div>
                <div className="code code--red"></div>
                <div className="code code--yellow"></div>
                <div className="code code--green"></div>
                <div className="code code--blue"></div>
                <div className="code code--red"></div>

                <div className="code code--green"></div>
                <div className="code code--yellow"></div>
                <div className="code code--blue"></div>
                <div className="code code--red"></div>
                <div className="code code--yellow"></div>
                <div className="code code--green"></div>
                <div className="code code--blue"></div>
                <div className="code code--red"></div>

                <div className="code code--green"></div>
                <div className="code code--yellow"></div>
                <div className="code code--blue"></div>
                <div className="code code--red"></div>
                <div className="code code--yellow"></div>
                <div className="code code--green"></div>
                <div className="code code--blue"></div>
                <div className="code code--red"></div>
              </div>
            </div>
          </div>
          <div className="girl">
            <div className="girl__head">
              <div className="girl__head--hair">
              </div>
              <div className="girl__head--fringe">
              </div>
            </div>
            <div className="girl__body"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default SamanthaAnimation;
